import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { WorkAreaSettingRecipientKeyDto } from 'core/dtos';
export interface IValidatedKeyCellRendererParams<T> extends ICellRendererParams<T> {
  recipientKey: WorkAreaSettingRecipientKeyDto;
  validators: ValidatorFn | AsyncValidatorFn | ValidatorFn[] | AsyncValidatorFn[] | undefined;
  validationMessage: string;
}

interface EditTextCellForm {
  value: FormControl<string>;
}
@Component({
  selector: 'app-recipient-key-cell',
  templateUrl: './recipient-key-cell.component.html',
  styleUrls: ['./recipient-key-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientKeyCellComponent implements ICellRendererAngularComp {
  rowData?: WorkAreaSettingRecipientKeyDto;
  form: FormGroup<EditTextCellForm> | undefined;
  validators: ValidatorFn | AsyncValidatorFn | ValidatorFn[] | AsyncValidatorFn[] | undefined;

  showTag = false;

  value = '';
  validationMessage = 'validation.fieldRequired';

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: IValidatedKeyCellRendererParams<WorkAreaSettingRecipientKeyDto>): void {
    this.validators = params.validators;
    this.validationMessage = params.validationMessage;

    this.form = new FormGroup<EditTextCellForm>({
      value: new FormControl('', { nonNullable: true, validators: this.validators }),
    });

    this.refresh(params);
  }

  refresh(params: IValidatedKeyCellRendererParams<WorkAreaSettingRecipientKeyDto>): boolean {
    this.value = params.value;
    this.rowData = params.data;
    this.showTag = this.rowData?.isDefault ? true : false;
    this.form?.patchValue({ value: this.value });
    this.cdRef.detectChanges();

    return true;
  }
}
