import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface IValidatedCellRendererParams<T> extends ICellRendererParams<T> {
  validators: ValidatorFn | AsyncValidatorFn | ValidatorFn[] | AsyncValidatorFn[] | undefined;
  validationMessage: string;
}

interface EditTextCellForm {
  value: FormControl<string>;
}

@Component({
  selector: 'app-ag-grid-validated-cell',
  templateUrl: './ag-grid-validated-cell.component.html',
  styleUrls: ['./ag-grid-validated-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridValidatedCellComponent implements ICellRendererAngularComp {
  value = '';
  validationMessage = 'validation.fieldRequired';
  form: FormGroup<EditTextCellForm> | undefined;
  validators: ValidatorFn | AsyncValidatorFn | ValidatorFn[] | AsyncValidatorFn[] | undefined;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: IValidatedCellRendererParams<string>): void {
    this.validators = params.validators;
    this.validationMessage = params.validationMessage;

    this.form = new FormGroup<EditTextCellForm>({
      value: new FormControl('', { nonNullable: true, validators: this.validators }),
    });

    this.refresh(params);
  }

  refresh(params: IValidatedCellRendererParams<string>): boolean {
    this.value = params.value;
    this.form?.patchValue({ value: params.value });

    this.cdRef.markForCheck();
    return true;
  }
}
