<div class="cell-container" [ngClass]="form?.controls?.value?.invalid ? 'invalid' : ''">
  <span>{{ value }}</span>

  <ds-error
    data-cy="cellValidationErrorIcon"
    *ngIf="form?.controls?.value?.invalid"
    ds-tooltip="{{ validationMessage | translate }}"></ds-error>

  <ds-tag
    *ngIf="showTag"
    style="float: right"
    class="tag mt-1x"
    [isDismissable]="false"
    [isInteractive]="false"
    data-cy="defaultTag">
    {{ 'settings.ipstAlertNowSettings.alertNowService.default' | translate }}
  </ds-tag>
</div>
